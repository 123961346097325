import React, { Component } from 'react';
import {Card, Container, Row} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faGithub, faFacebookF, faGitlab, faTwitter, faStrava, faTelegram, faSkype, faDocker } from '@fortawesome/free-brands-svg-icons';
 
class SocialWeb extends Component {

    state={
        attr : {
            faLinkedinIn : { 
                size : "3x",
                link : "https://www.linkedin.com/in/expovin/",
                desc  : "Non chiedetemi il CV, il mio profilo Linkedin è sicuramente molto piu dettagliato e aggiornato di un qualsiasi CV su PDF"
            },
            faGithub : { 
                size : "3x",
                link : "https://github.com/expovin/",
                desc : "Sviluppare applicazioni non è il mio task principale, è qualcosa che ogni tanto capita e quando capita, spesso mi rilassa...\
                        Nel tempo ho accumulato diversi progetti, molti di questi li utilizzo come referenze ..."
            },
            faGitlab : { 
                size : "3x",
                link: "https://gitlab.com/expovin",
                desc : "Qui ho solo progetti privati, usato principalmente per scopi professionali."
            },
            faDocker : { 
                size : "3x",
                link: "https://hub.docker.com/u/expovin",
                desc : "Docker è una delle architetture che mi affascina di piu, sul mio profilo ho qualche esperimento"
            },            
            faTwitter : { 
                size : "3x",
                link : "https://twitter.com/expovin",
                desc : "Non è tra i social che utilizzo piu spesso, lo si può vedere dai miei ultimi aggiornamenti "
            }, 
            faFacebookF : { 
                size : "3x",
                link : "https://www.facebook.com/expovin",
                desc : "Qui c'è invece un po di me come persona con amici, parenti e famiglia. Tendo a non avere connessioni con persone che non conosco personalmente. "
            },
            faStrava : { 
                size : "3x",
                link : "https://www.strava.com/athletes/7427964",
                desc : "Una professione sedentaria porta facilmente ad avere hobbies che tengono in movimento. Mi piace correre, la mezza maratona è la distanza massima \
                        su cui mi sono misurato fin ora. In progetto per il prossimo futuro una maratona intera. Mi piace anche andare in bici e quando il tempo non permette \
                        di poter gioire delle meraviglie che ci circondano, mi alleno indor con Zwift"
            },
            faTelegram : { 
                size : "3x",
                link : "@expovin",
                desc : "Lo utilizzo con amici e conoscenti "
            },
            faSkype : { 
                size : "3x",
                link : "@expovin78",
                desc : "Mi trovate qui.... qualche volta"
            }
        },
        webSocial:null
    }

    updateDimensions = () => {
        let width = window.innerWidth;

        if(width > 1500){
            let attr = this.state.attr;
            for (var key in attr) {
                if (attr.hasOwnProperty(key)) {
                    attr[key].size="3x"
                }
            }            
            this.setState({attr:attr})               
        }


        if(width < 1500 && width > 1250){
            let attr = this.state.attr;
            for (var key in attr) {
                if (attr.hasOwnProperty(key)) {
                    attr[key].size="2x"
                }
            }            
            this.setState({attr:attr})               
        }


        if(width < 1250){
            let attr = this.state.attr;
            for (var key in attr) {
                if (attr.hasOwnProperty(key)) {
                    attr[key].size="1x"
                }
            }            
            this.setState({attr:attr})               
        }

    }


    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    mouseEnter = (e,name) => {
        let attr = this.state.attr;
        let nSize= parseInt(attr[name].size[0],10)
        attr[name].size=(nSize+1)+"x";
        this.setState({attr:attr, webSocial:name})        
    }

    mouseLeave = (e,name) => {
        let attr = this.state.attr;
        let nSize= parseInt(attr[name].size[0],10)
        attr[name].size=(nSize-1)+"x";
        this.setState({attr:attr})      
    }    

    sectionExit = (e) =>{
        this.setState({webSocial:null})      
    }

    webSocial = () => {
        if(this.state.webSocial)
            return(
                <div>
                    <hr />
                    <a href={this.state.attr[this.state.webSocial].link}>{this.state.attr[this.state.webSocial].link}</a>
                    <p>{this.state.attr[this.state.webSocial].desc}</p>
                </div>

            )
        else
            return null;
    }

    render(){
        return(
            <Container id="socialWeb">
                <Card>
                <Card.Body>
                    <Card.Title>Social Identity</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Riusiamo ciò che c'è di buono ... </Card.Subtitle>
                    
                    <Card.Text onMouseLeave={this.sectionExit}>
                        <p>
                            Ritengo sia superfluo, descriversi in un sito personale quando, volontariamente (o meno) lasciamo
                            una traccia così dettagliata di noi sul web. Non sono mai stato un fautore della duplicazione delle
                            informazioni, così ho raccolto qui in modo ordinato un po di me, professionale e personale ...
                        </p>
                        
                        <hr/>
                        <Row>
                            <FontAwesomeIcon  className="iconMark" icon={faLinkedinIn} onMouseEnter={(e) => this.mouseEnter(e,"faLinkedinIn")} onMouseLeave={(e) => this.mouseLeave(e,"faLinkedinIn")} color="purple" size={this.state.attr.faLinkedinIn.size} /><div className="vl"></div>
                            <FontAwesomeIcon  className="iconMark" icon={faGithub} onMouseEnter={(e) => this.mouseEnter(e,"faGithub")} onMouseLeave={(e) => this.mouseLeave(e,"faGithub")} color="purple" size={this.state.attr.faGithub.size}/><div className="vl"></div>                        
                            <FontAwesomeIcon  className="iconMark" icon={faGitlab} onMouseEnter={(e) => this.mouseEnter(e,"faGitlab")} onMouseLeave={(e) => this.mouseLeave(e,"faGitlab")} color="purple" size={this.state.attr.faGitlab.size}/><div className="vl"></div>
                            <FontAwesomeIcon  className="iconMark" icon={faDocker} onMouseEnter={(e) => this.mouseEnter(e,"faDocker")} onMouseLeave={(e) => this.mouseLeave(e,"faDocker")} color="purple" size={this.state.attr.faDocker.size}/><div className="vl"></div>
                            <FontAwesomeIcon  className="iconMark" icon={faTwitter} onMouseEnter={(e) => this.mouseEnter(e,"faTwitter")} onMouseLeave={(e) => this.mouseLeave(e,"faTwitter")} color="purple" size={this.state.attr.faTwitter.size}/><div className="vl"></div>
                            <FontAwesomeIcon  className="iconMark" icon={faFacebookF} onMouseEnter={(e) => this.mouseEnter(e,"faFacebookF")} onMouseLeave={(e) => this.mouseLeave(e,"faFacebookF")} color="purple" size={this.state.attr.faFacebookF.size}/><div className="vl"></div>
                            <FontAwesomeIcon  className="iconMark" icon={faStrava} onMouseEnter={(e) => this.mouseEnter(e,"faStrava")} onMouseLeave={(e) => this.mouseLeave(e,"faStrava")} color="purple" size={this.state.attr.faStrava.size}/><div className="vl"></div>
                            <FontAwesomeIcon  className="iconMark" icon={faTelegram} onMouseEnter={(e) => this.mouseEnter(e,"faTelegram")} onMouseLeave={(e) => this.mouseLeave(e,"faTelegram")} color="purple" size={this.state.attr.faTelegram.size}/><div className="vl"></div>
                            <FontAwesomeIcon  className="iconMark" icon={faSkype} onMouseEnter={(e) => this.mouseEnter(e,"faSkype")} onMouseLeave={(e) => this.mouseLeave(e,"faSkype")} color="purple" size={this.state.attr.faSkype.size}/>
                        </Row>
                        
                        {this.webSocial()}

                    </Card.Text>

                </Card.Body>
                </Card>                
            </Container>
        )
    }

}
export default SocialWeb;