import {Row, Col, Container} from 'react-bootstrap';
import AppIndex from './app-index/app-index';
import Incipit from './incipit/incipit';
import Logo from './logo/logo';
import SocialWeb from './social-web/social-web';
import './App.css';

function App() {
  return (
    <div>

        <Row className="section"><Logo /></Row>
        <Row className="section"><Incipit /></Row>
        <Row className="section"><SocialWeb /></Row>
        <Row className="section"><AppIndex /></Row>

    </div>
  );
}

export default App;
