import React, { Component } from 'react';
import {Image, Row, Col, Card, ListGroup, Table, Form, Container} from 'react-bootstrap';
import FileStation from '../images/syno/FileStation.png'
import SurveillanceStation from '../images/syno/SurveillanceStation.png'
import DokuWiki from '../images/syno/DokuWiki.png'

class AppIndex extends Component {

    apps = [
        {
            nome : "FileStation",
            img : "http://syno.expovin.it/CDN/Syno/FileStation.png",
            link : "https://syno.expovin.it/file/"
        },
        {
            nome : "SurveillanceStation",
            img : "http://syno.expovin.it/CDN/Syno/SurveillanceStation.png",
            link : "https://syno.expovin.it/cam/"
        },
        {
            nome : "DokuWiki",
            img : "http://syno.expovin.it/CDN/Syno/DokuWiki.png",
            link : "https://syno.expovin.it/dokuwiki/"
        },
        {
            nome : "dsGet",
            img : "http://syno.expovin.it/CDN/Syno/DownloadStation.png",
            link : "https://syno.expovin.it/get/"
        },
        {
            nome : "NoteStation",
            img : "http://syno.expovin.it/CDN/Syno/NoteStation.png",
            link : "https://syno.expovin.it/note/"
        },
        {
            nome : "VideoStation",
            img : "http://syno.expovin.it/CDN/Syno/VideoStation.png",
            link : "https://syno.expovin.it/video/"
        },
        {
            nome : "PhotoStation",
            img : "http://syno.expovin.it/CDN/Syno/PhotoStation.png",
            link : "https://syno.expovin.it/photo/"
        },
        {
            nome : "Gianluca",
            img : "http://syno.expovin.it/CDN/IMGs/GianlucaManga_SMALL.png",
            link : "https://gianluca.expovin.it/"
        },
        {
            nome : "Alice",
            img : "http://syno.expovin.it/CDN/IMGs/AliMangaSquare.png",
            link : "https://alice.expovin.it/"
        },
        {
            nome : "Registro",
            img : "http://syno.expovin.it/CDN/IMGs/Registro.png",
            link : "https://registro.expovin.it/"
        },
        {
            nome : "Qlik Cloud",
            img : "https://www.bardess.com/wp-content/uploads/2020/07/pngguru.com_-300x300.png",
            link : "https://expovin.eu.qlikcloud.com/"
        }                                                                
    ]

    applicazioni = () => {
        return(
            this.apps.map(app => 
                    <Col className="AppButton" sm={6} md={4} lg={3} xl={2}>
                        <a href={app.link}>
                            <Image src={app.img} alt={app.img} width="125px"/>
                            <p>{app.nome}</p>
                        </a>

                    </Col>
                    )
        )
        
    }

    render(){
        return(
            <Container id="AppIndex">
                <Card>
                <Card.Body>
                    <Card.Title>Indice delle applicazioni</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">...qualcosa di utile a me e a chi mi conosce....</Card.Subtitle>
                    <Card.Text>
                        Quando i link a progetti personali, condivisi, cominciano ad essere un discreto numero [...] 
                        allora è giunto il momento di dargli un posto tutto loro dove poterli raccogliere in modo ordinato
                    </Card.Text>
                        <Row>
                            {this.applicazioni()}
                        </Row>

                </Card.Body>
                </Card>                
            </Container>
        )
    }

}
export default AppIndex;