import React, { Component } from 'react';
import {Button, Row, Col, Card, Image, Container} from 'react-bootstrap';
import logo from '../images/VinceMangaSMALL.png'

class Logo extends Component {

    render(){
        return(
            <Container id="logo">
                <Row>
                    <Col lg={4}><Image src={logo} alt={logo} roundedCircle  width="200px"/>   </Col>    
                    <Col lg={8}>
                        <h1 id="Titolo">Expovin</h1>
                        <hr />
                        <h4>Vincenzo Esposito</h4>
                    </Col>
                </Row>
                               
            </Container>
        )
    }

}
export default Logo;