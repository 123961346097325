import React, { Component } from 'react';
import {Button, Row, Col, Card, ListGroup, Table, Form, Container} from 'react-bootstrap';

class Incipit extends Component {

    render(){
        return(
            <Container id="incipit">
                <Card>
                <Card.Body>
                    <Card.Title>Incipit</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Benvenuto visitatore</Card.Subtitle>
                    <Card.Text>
                        Caro visitatore, questo dominio è di utilizzo privato. Ho raccolto in questa pagina alcune
                        informazioni che possono essere utili ad altri... altre che sono di aiuto a me e pochi miei intimi.
                    </Card.Text>

                </Card.Body>
                </Card>                
            </Container>
        )
    }

}
export default Incipit;